<template>
  <div class="w-100 h-100">
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      chartId="abnormalChart"
      height="100%"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          tooltip: {
            show: true,
            confine: true,
            trigger: "axis",
            formatter: (params) => {
              console.log(params)
              var res = `${params[0].axisValue}<br/>`;
              res += `${params[0].marker}设备异常${params[0].seriesName}：${params[0].value}%<br/>`;
              if (params[1]) {
                res += `${params[1].marker}${params[1].seriesName}设备：${params[1].value}台<br/>`;
              }
              if (params[2]) {
                res += `${params[2].marker}${params[2].seriesName}设备：${params[2].value}台<br/>`;
              }
              if (params[3]) {
                res += `${params[3].marker}${params[3].seriesName}设备：${params[3].value}台`;
              }
              return res;
            }
          },
          legend: {
            data: ["火警", "故障", "离线", "增长率"],
            textStyle: {
              color: "#11d0c9"
            },
            bottom: "0%"
          },
          grid: {
            width: "80%",
            top: "10%"
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#FFFFFF"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: "#11d0c9"
            }
          },
          yAxis: [
            {
              minInterval: 1, // 这里设置为1，确保刻度值只显示整数
              splitLine: { 
                show: true ,
                lineStyle: {
                  type: 'dashed'
                }
              },
              axisLine: {show: false},
              axisLabel: {
                formatter: "{value}",
                color: "#11d0c9"
              }
            },
            {
              splitLine: {show: false},
              axisLine: {show: false},
              axisLabel: {
                formatter: "{value}%",
                color: "#11d0c9"
              }
            }
          ],
          series: [
            {
              name: "增长率",
              type: "line",
              smooth: false,
              showAllSymbol: true,
              symbol: "none",
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#2FC25B"
                }
              },
              data: newData.rateData
            },
            {
              name: "火警",
              type: "bar",
              itemStyle: {
                normal: {
                  color: '#FF4B34'
                }
              },
              data: newData.bar1Data
            },
            {
              name: "故障",
              type: "bar",
              barGap: "-100%",
              itemStyle: {
                normal: {
                  color: '#FFC700'
                }
              },
              z: -12,
              data: newData.bar2Data
            },
            {
              name: "离线",
              type: "bar",
              barGap: "-100%",
              itemStyle: {
                normal: {
                  color: '#999999'
                }
              },
              z: -13,
              data: newData.bar3Data
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>